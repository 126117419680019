import gql from 'graphql-tag';

export const FRAGMENT_RECIPE = gql`
  fragment recipe on Recipe {
    id
    created_at
    last_edited_at
    version
    attributes {
      dosage
      dripper
      has_rinse
      rinse_vol
      rinse_pattern {
        id
      }
      name
      notes
      size
    }
    relationships {
      created_by {
        uid
        attributes {
          email
        }
      }
      last_edited_by {
        uid
        attributes {
          email
        }
      }
      machines {
        id
        attributes {
          name
        }
        relationships {
          stations {
            id
            relationships {
              recipe {
                id
              }
            }
          }
        }
      }
      organization {
        id
        attributes {
          name
        }
      }
      pours {
        id
        attributes {
          pour_vol
          radius_factor
          time_pour_to_pour
          position
        }
        relationships {
          pattern {
            id
          }
        }
      }
    }
  }
`;

import gql from 'graphql-tag';
import { FRAGMENT_RECIPE } from './fragments';

export const FETCH_MACHINES = gql`
  {
    machines {
      id
      attributes {
        name
        status
      }
      relationships {
        organization {
          id
          attributes {
            name
          }
        }
        stations {
          id
          relationships {
            recipe {
              id
            }
          }
        }
      }
    }
  }
`;

export const FETCH_MACHINE = gql`
  query Machine($id: ID!) {
    machine(id: $id) {
      id
      attributes {
        name
        status
      }
      relationships {
        recipes {
          id
          attributes {
            name
            size
          }
        }
        stations {
          id
          attributes {
            index
            position
          }
          relationships {
            recipe {
              id
              attributes {
                dripper
                dosage
                name
                size
                total_water_volume
                minimum_contact_time
                master_ratio
              }
              relationships {
                pours {
                  id
                  attributes {
                    position
                    pour_vol
                    time_pour_to_pour
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const FETCH_ME = gql`
  {
    me {
      uid
      attributes {
        name
        email
        poursteadyAdmin
        role
      }
      relationships {
        organizations {
          id
        }
      }
    }
  }
`;

export const FETCH_ORGANIZATIONS = gql`
  {
    organizations {
      id
      attributes {
        name
      }
    }
  }
`;

export const FETCH_PATTERNS = gql`
  {
    patterns {
      id
      attributes {
        description
      }
    }
  }
`;

export const FETCH_RECIPE = gql`
  query Recipe($id: ID!) {
    recipe(id: $id) {
      ...recipe
    }
  }
  ${FRAGMENT_RECIPE}
`;

export const FETCH_RECIPES = gql`
  {
    recipes {
      id
      attributes {
        name
        dosage
        dripper
        size
      }
      relationships {
        organization {
          id
          attributes {
            name
          }
        }
        machines {
          id
        }
      }
    }
  }
`;

export const FETCH_USERS = gql`
  {
    users {
      uid
      attributes {
        name
        email
        poursteadyAdmin
        role
      }
      relationships {
        organizations {
          id
          attributes {
            name
          }
        }
      }
    }
  }
`;

export const GET_ORG_STATUS = gql`
  query GetOrgStatus($oid: ID!) {
    getOrgStatus(oid: $oid) {
      __typename
      organizations {
        __typename
        id
        hasAdmin
        organizationName
        subscriptions {
          __typename
          active
          created
          currentPeriodStart
          currentPeriodEnd
        }
        activeUsers {
          __typename
          role
          active
          uid
        }
        inactiveUsers {
          __typename
          role
          active
          uid
        }
      }
    }
  }
`;

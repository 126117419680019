import gql from 'graphql-tag';
import { FRAGMENT_RECIPE } from './fragments';

export const TOGGLE_ORG_OWNER = gql`
  mutation User($organization: ID!, $user: ID!) {
    toggleOrganizationOwner(organization: $organization, user: $user) {
      uid
      attributes {
        email
        name
      }
      relationships {
        organizations {
          id
        }
      }
    }
  }
`;

export const ASSIGN_ORG_TO_MACHINE = gql`
  mutation Machine($machine: ID!, $organization: ID) {
    assignMachineToOrganization(
      machine: $machine
      organization: $organization
    ) {
      id
      attributes {
        name
      }
      relationships {
        organization {
          id
        }
      }
    }
  }
`;

export const CREATE_ORGANIZATION = gql`
  mutation Organization($name: String!) {
    createOrganization(name: $name) {
      id
      attributes {
        name
      }
    }
  }
`;

export const TOGGLE_POURSTEADY_ADMIN = gql`
  mutation User($uid: ID!) {
    togglePoursteadyAdmin(uid: $uid) {
      uid
      attributes {
        poursteadyAdmin
      }
      relationships {
        organizations {
          id
        }
      }
    }
  }
`;

export const CREATE_USER = gql`
  mutation User($email: String!, $name: String) {
    createUser(email: $email, name: $name) {
      uid
      attributes {
        email
        name
      }
    }
  }
`;

export const EDIT_USER = gql`
  mutation User($uid: ID!, $attributes: JSONObject!) {
    editUser(uid: $uid, attributes: $attributes) {
      uid
      attributes {
        email
        name
        role
      }
    }
  }
`;

export const DELETE_USER = gql`
  mutation Machine($uid: ID!) {
    deleteUser(uid: $uid)
  }
`;

export const UPDATE_RECIPE = gql`
  mutation Recipe($id: ID!, $data: JSON!) {
    updateRecipe(id: $id, data: $data) {
      ...recipe
    }
  }
  ${FRAGMENT_RECIPE}
`;

export const CREATE_RECIPE = gql`
  mutation Recipe($data: JSON!) {
    createRecipe(data: $data) {
      ...recipe
    }
  }
  ${FRAGMENT_RECIPE}
`;

export const DELETE_RECIPE = gql`
  mutation Boolean($id: ID!) {
    deleteRecipe(id: $id)
  }
`;

export const ASSIGN_STATION_RECIPE = gql`
  mutation Machine($machine: ID!, $stationIndex: Int!, $recipe: ID!) {
    assignStationRecipe(
      machine: $machine
      stationIndex: $stationIndex
      recipe: $recipe
    ) {
      id
      attributes {
        name
        status
      }
      relationships {
        recipes {
          id
          attributes {
            name
            size
          }
        }
        stations {
          id
          attributes {
            index
            position
          }
          relationships {
            recipe {
              id
              attributes {
                dripper
                dosage
                name
                size
                total_water_volume
                minimum_contact_time
                master_ratio
              }
              relationships {
                pours {
                  id
                  attributes {
                    position
                    pour_vol
                    time_pour_to_pour
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_MACHINE_NAME = gql`
  mutation Machine($id: ID!, $name: String!) {
    updateMachineName(id: $id, name: $name) {
      id
      attributes {
        name
      }
    }
  }
`;

export const CREATE_MACHINE = gql`
  mutation Machine(
    $id: ID!
    $name: String
    $organization: ID
    $stations: Int!
  ) {
    createMachine(
      id: $id
      name: $name
      organization: $organization
      stations: $stations
    ) {
      id
      attributes {
        name
        status
      }
      relationships {
        organization {
          id
          attributes {
            name
          }
        }
        stations {
          id
          relationships {
            recipe {
              id
            }
          }
        }
      }
    }
  }
`;

export const BUST_CACHE = gql`
  mutation Boolean {
    bustCache
  }
`;

export const GEN_ORG_SLUG = gql`
  mutation genOrganizationSlug {
    genOrganizationSlug {
      id
    }
  }
`;

export const ADD_USER_TO_ORG = gql`
  mutation AddUserToOrg($uid: ID!, $oid: ID!, $role: String!) {
    addUserToOrganization(uid: $uid, oid: $oid, role: $role)
  }
`;

export const INITIALIZE_PAY_ACCOUNT = gql`
  mutation PayForOrg(
    $oid: ID!
    $email: String!
    $password: String!
    $organizationName: String
    $billingInterval: BillingInterval
  ) {
    payForOrganization(
      oid: $oid
      email: $email
      password: $password
      organizationName: $organizationName
      billingInterval: $billingInterval
    ) {
      oid
      uid
      next
    }
  }
`;

export const ADD_NEW_USER_TO_ORG = gql`
  mutation Register(
    $email: String!
    $password: String!
    $oid: ID!
    $role: String!
  ) {
    registerOrganizationUser(
      email: $email
      password: $password
      oid: $oid
      role: $role
    ) {
      oid
      uid
      role
    }
  }
`;

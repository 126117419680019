import React, { useState, useEffect } from 'react';
import { ApolloProvider } from '@apollo/client';
import { ApolloClient, HttpLink, concat, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import AuthContext from 'context/AuthContext';
import Loading from 'components/Loading';
import loadable from '@loadable/component';
import * as Sentry from '@sentry/browser';
import isProduction from 'utils/isProduction';
import { createClient } from '@supabase/supabase-js';
import { GET_ORG_STATUS } from 'state/queries';
import {
  ADD_NEW_USER_TO_ORG,
  ADD_USER_TO_ORG,
  GEN_ORG_SLUG,
  INITIALIZE_PAY_ACCOUNT,
} from 'state/mutations';

const options = { fallback: <Loading /> };
const LoadableLogin = loadable(() => import('views/Login'), options);
const LoadableApp = loadable(() => import('./App'), options);

const SUPABASE_URL = process.env.REACT_APP_SUPABASE_URL;
const SUPABASE_ANON_KEY = process.env.REACT_APP_SUPABASE_ANON_KEY;

const supabase = createClient(SUPABASE_URL, SUPABASE_ANON_KEY);
const link = new HttpLink({ uri: process.env.REACT_APP_GRAPHQL_ENDPOINT });

const authLink = setContext(async (operation, { headers }) => {
  const { data } = await supabase.auth.getSession();
  const token = data?.session?.access_token;

  return {
    headers: {
      ...headers,
      authorization: token || null,
    },
  };
});

const cache = new InMemoryCache({
  // cacheRedirects: {},
  typePolicies: {
    RecipeAttributes: {
      merge: true,
    },
    RecipeRelationships: {
      merge: true,
    },
    MachineRelationships: {
      merge: true,
    },
    MachineAttributes: {
      merge: true,
    },
  },
});

const client = new ApolloClient({
  link: concat(authLink, link),
  cache,
});

const Authorization = (props) => {
  const [initialized, setInitialized] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [authStatus, setAuthStatus] = useState('IDLE');
  const [resetStatus, setResetStatus] = useState('IDLE');
  const [signUpStatus, setSignUpStatus] = useState('IDLE');
  const [role, setRole] = useState(null);
  const [orgs, setOrgs] = useState([]);
  const [error, setError] = useState(null);
  const [orgUserInfo, setOrgUserInfo] = useState(null);
  const [orgStatus, setOrgStatus] = useState(null);
  const [organization, setOrganization] = useState(null);
  const [paywallSession, setPaywallSession] = useState({});

  useEffect(() => {
    // Only one will be returned even though orgs are an array
    const organization = orgStatus?.organizations.at(-1);
    const { hasAdmin, id, activeUsers, subscriptions } = organization || {};
    setOrganization({
      hasAdmin,
      id,
      oid: id,
      activeUsers,
      subscriptions,
      inactive: !!subscriptions?.length,
    });
  }, [orgStatus]);

  useEffect(() => {
    if (paywallSession?.next) {
      window.location = paywallSession.next;
    }
  }, [paywallSession]);

  useEffect(() => {
    const handleOrgUserInfo = async () => {
      if (
        orgUserInfo &&
        organization?.hasOwnProperty('hasAdmin') &&
        organization.hasAdmin &&
        organization?.activeUsers?.length
      ) {
        const { oid, email, password, role } = orgUserInfo;
        // Pay for a new user and add it to the organization
        await addNewUserToOrg({
          email,
          password,
          oid,
          role,
        });
      }

      if (
        orgUserInfo &&
        organization?.hasOwnProperty('hasAdmin') &&
        !organization.hasAdmin
      ) {
        const { organizationName, ...data } = orgUserInfo;
        // Pay for a new owner and create the organization
        await createOrgWithOwner({ ...data, organizationName });
      }
    };

    handleOrgUserInfo();
  }, [orgUserInfo, organization]);

  useEffect(() => {
    supabase.auth.onAuthStateChange((currentAuthState, session) => {
      setError(null);
      setAuthStatus('PENDING');
      // Strange handler suggested by Supabase documentation for avoiding async callback
      setTimeout(async () => {
        if (session?.user) {
          const user = session.user;
          const token = session.access_token;
          if (user) {
            if (isProduction) {
              Sentry.getCurrentScope().setUser({ id: user.id });
            }

            if (window && window.analytics) {
              window.analytics.identify(user.uid, {
                ...(user?.user_metadata?.displayName && {
                  name: user.user_metadata.displayName,
                }),
                email: user.email,
              });
            }

            if (token) {
              setLoggedIn(true);
            } else {
              setLoggedIn(false);
            }

            setAuthStatus('FULFILLED');
            setInitialized(true);
          } else {
            setLoggedIn(false);
            setAuthStatus('IDLE');
            setInitialized(true);
          }
        } else {
          setLoggedIn(false);
          setAuthStatus('IDLE');
          setInitialized(true);
        }
      }, 0);
    });
  }, []);

  if (!initialized) return <Loading />;

  async function getOrgStatus(oid) {
    const { data, loading } = await client.query({
      query: GET_ORG_STATUS,
      variables: {
        oid: oid,
      },
    });

    if (!loading && data?.getOrgStatus) {
      setOrgStatus(data?.getOrgStatus);
      return data?.getOrgStatus?.organizations?.at(-1);
    }
  }

  async function addNewUserToOrg({ email, password, oid, role }) {
    const { data, loading, error } = await client
      .mutate({
        mutation: ADD_NEW_USER_TO_ORG,
        variables: {
          email,
          password,
          oid,
          role,
        },
      })
      .catch((error) => {
        console.error(error);
        window.location = '/payment/error';
      });

    if (!loading && !error) {
      window.location = '/payment?payment-success=true';
    }
    if (error) {
      window.location = '/payment/error';
    }
  }

  async function addUserToOrg(uid, oid, role) {
    const { data } = client.mutate({
      mutation: ADD_USER_TO_ORG,
      variables: {
        uid,
        oid,
        role,
      },
    });

    if (data?.addUserToOrg) {
      const { oid, uid, role } = data.addUserToOrg;
      setOrgUserInfo({ oid, uid, role });
    }
  }

  async function createOrgWithOwner({
    oid,
    email,
    password,
    organizationName,
  }) {
    // TODO: Only create if or does not have admin/owner already
    // getOrgStatus() has the data
    console.info({ oid, email, password, organizationName });
    if (!email || !password || !oid) {
      return;
    }
    const { data, loading, error } = await client.mutate({
      mutation: INITIALIZE_PAY_ACCOUNT,
      variables: {
        oid,
        email,
        password,
        organizationName,
        // [month, year]
        billingInterval: 'month',
      },
    });

    if (data?.payForOrganization) {
      setPaywallSession(data?.payForOrganization);
    }
  }

  const signInUser = async (email = '', password = '') => {
    try {
      setAuthStatus('PENDING');
      setError(null);
      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });

      console.info({ user: data?.user, error });

      setRole(data?.user?.role || 'authenticated');

      if (error || !data) {
        setError(error);
        setAuthStatus('REJECTED');
        return error;
      }
      return true;
    } catch (e) {
      return e;
    }
  };

  /**
   * TODO(PSDev): Match Supabase Flow
   * 0) Setup email provider details in Supabase config
   * 1) Configure password reset email in Supabase
   * 2) Create a route to accept new password if DNE
   * 3) Test
   * */
  const resetPassword = async (email) => {
    try {
      setError(null);
      setResetStatus('PENDING');

      const { data, error: e } = await supabase.auth.resetPasswordForEmail(
        email,
        {
          redirectTo: 'http://localhost:3000/password-reset',
        },
      );

      if (data && !e) {
        setResetStatus('FULFILLED');
      } else {
        setError(e);
        setResetStatus('REJECTED');
      }
    } catch (e) {
      return e;
    }
  };

  const signOutUser = async () => {
    try {
      await supabase.auth.signOut();
      setLoggedIn(false);
      setAuthStatus('IDLE');
      await client.resetStore();
      return true;
    } catch (e) {
      return e;
    }
  };

  const signUpUser = async (
    email = '',
    password = '',
    role = '',
    organizationID = '',
    organizationName = '',
  ) => {
    try {
      setSignUpStatus('PENDING');
      setError(null);

      if (role && organizationID && organizationName) {
        setOrgUserInfo({
          email,
          password,
          role,
          oid: organizationID,
          organizationName,
        });
      } else {
        const { data, error } = await supabase.auth.signUp({
          email,
          password,
          options: {
            data: {
              organizationID,
            },
          },
        });
        if (data && !error) {
          setSignUpStatus('FULFILLED');
        } else {
          setError(error);
          setSignUpStatus('REJECTED');
        }
        return true;
      }
    } catch (error) {
      return error;
    }
  };

  const generateOrgSlug = async () => {
    const result = await client.mutate({ mutation: GEN_ORG_SLUG });
    setOrgs([{ id: result.data.genOrganizationSlug.id }]);
    return result;
  };

  const authContext = {
    actions: {
      addUserToOrg,
      getOrgStatus,
      resetPassword,
      signInUser,
      signOutUser,
      signUpUser,
      generateOrgSlug,
    },
    error,
    status: {
      auth: authStatus,
      reset: resetStatus,
      signUp: signUpStatus,
    },
    attributes: {
      role: role || 'authenticated',
      organizations: orgs,
    },
  };

  if (!loggedIn) {
    return (
      <AuthContext.Provider value={authContext}>
        <LoadableLogin />
      </AuthContext.Provider>
    );
  }

  return (
    <AuthContext.Provider value={authContext}>
      <ApolloProvider client={client}>
        <LoadableApp />
      </ApolloProvider>
    </AuthContext.Provider>
  );
};

export default Authorization;
